@tailwind base;
@tailwind components;
@tailwind utilities;

@media all {
	.print-only {
		display: none;
	}
}

@media print {
	.print-hidden {
		display: none;
	}
	.print-only {
		display: initial;
	}
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Gujarati:wght@100;200;300;400;500;600;700;800;900&display=swap");
